@import "../_theme.scss";

.team-member-card {
    position: relative;
    width: 100%;
    margin: 20px;
    box-sizing: border-box;
    max-width: 600px;

    &>* {
        border-radius: 10px;
    }

    .team-member-card-border {
        position: absolute;
        border-color: $theme-primary;
        border-style: solid;
        width: 40px;
        height: 40px;
        display: block;
        z-index: -1;

        &.top-left {
            top: -2px;
            left: -2px;
            border-top-width: 3px;
            border-left-width: 3px;
        }

        &.top-right {
            top: -2px;
            right: -2px;
            border-top-width: 3px;
            border-right-width: 3px;
        }

        &.bottom-left {
            bottom: -2px;
            left: -2px;
            border-bottom-width: 3px;
            border-left-width: 3px;
        }

        &.bottom-right {
            bottom: -2px;
            right: -2px;
            border-bottom-width: 3px;
            border-right-width: 3px;
        }
    }

    .team-member-card-background {
        background-color: white;
        padding: 20px;
        width: 100%;
        height: 100%;
        box-sizing: border-box;
    }
    .team-member-headshot {
        width: 80%;
        margin: auto;

        .team-member-headshot-image {
            max-width: 100%;
            max-height: 100%;
            width: auto;
            height: auto;
            border-radius: 1000px;
            overflow: hidden;
            aspect-ratio: 1;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }
}