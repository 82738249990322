@font-face {
  font-family: 'JosefinSans Regular';
  src: url(fonts/static/JosefinSans-Regular.ttf);
}

@font-face {
  font-family: 'Montserrat Regular';
  src: url(fonts/static/Montserrat-Regular.ttf);
}

.App {
  text-align: center;
  position: relative;
  display: flex;
  flex-direction: row;
  width: 100vw;
  height: 100vh;
}

.navigator-option *, .team-member-card-background *, .landing-cover-text *, .paper-paragraph > h4 {
  font-family: "JosefinSans Regular", "Tahoma" !important;
}

* {
  font-family: "Montserrat Regular", "Tahoma";
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.sidebar-navigator {
  height: 100%;
}

.page {
  height: 100%;
  overflow-y: scroll;
}

@media(min-width: 640px) {
  .sidebar-navigator {
    width: 300px;
  }
  
  .page {
    width: calc(100% - 300px);
    z-index: 1;
  }
}

@media(max-width: 639.9999px) {
  .sidebar-navigator {
    position: absolute !important;
    left: 0;
    top: 0;
    width: 100%;
  }
  
  .page {
    position: absolute !important;
    left: 0;
    top: 0;
    width: 100%;
  }

  .navigator-root.on {
    z-index: 19;
  }
}

body {
  overflow: hidden;
}