#gallery {
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
}

.gallery-image {
    width: 100%;
    position: relative;

    img {
        width: 100%;
    }
}