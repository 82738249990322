@import "../_theme.scss";

.navigator-root {
    position: relative;
    background-color: $theme-primary;
}

@media(min-width: 640px) {
    .navigator-drawer-button {
        display: none;
    }
}

@media(max-width: 639.999px) {
    .navigator-root {
        &.off {
            transform: translateX(-100%);
        }

        &.on {
            transition: all 0.3s ease-in;
        }
    }
}

.navigator-title {
    position: absolute;
    display: flex;
    flex-direction: column;
    left: 0;
    top: 40px;
    width: calc(100% - 20px);
    padding: 0 10px;
    font-size: large;
    user-select: none;
    cursor: pointer;
    color: $theme-primary-text-color;

    & > span {
        font-weight: 700;
    }
    
    & > span:nth-child(1) {
        font-size: 16px;
    }
    
    & > span:nth-child(2) {
        font-size: 14px;
    }
    
    & > span:nth-child(3) {
        font-size: 10px;
    }
    
    &:hover {
        color: $theme-primary-text-hover;
    }
}

.logo-container {
    position: relative;
    width: 250px;
    height: 250px;
    border-radius: 50%;
    overflow: hidden;
}

.logo-container::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: radial-gradient(rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 58%, black 59%);
}

.sidebar-navigator {
    position: relative;
    overflow-y: auto;
    overflow-x: hidden;
    z-index: 1000 !important;
}

* {
    scrollbar-color: $scroll-thumb $scroll-track;
    scrollbar-width: thin;
}
*::-webkit-scrollbar {
    width: 12px;               /* width of the entire scrollbar */
  }
*::-webkit-scrollbar-track {
    background: $scroll-track;        /* color of the tracking area */
  }
*::-webkit-scrollbar-thumb {
    background-color: $scroll-thumb;    /* color of the scroll thumb */
    border-radius: 20px;       /* roundness of the scroll thumb */
    border: 3px solid $scroll-track;  /* creates padding around scroll thumb */
  }

.navigator-options {
    position: absolute;
    left: 0;
    top: 380px;
    width: 100%;
    padding-top: 120px;
    padding-bottom: 40px;
    transform: translate(0, -30%);
    display: flex;
    flex-direction: column;
}

.navigator-option {
    padding: 12px 20px;
    user-select: none;
    cursor: pointer;
    color: $theme-primary-text-color;
    font-weight: 500;

    &:hover {
        color: $theme-primary-text-hover;
    }
}

#navigator-option-selected {
    background-color: $theme-highlight;
    position: relative;
    color: $theme-highlight-text-color;

    &:hover {
        color: $theme-highlight-text-hover;
    }

    &::after {
        position: absolute;
        display: block;
        content: '';
        top: 0px;
        right: -1px;
        width: 3px;
        height: 100%;
        background-color: $theme-highlight;
        z-index: -1;
    }
}

.navigator-option:not(#navigator-option-selected) {
    .curve-block {
        display: none;
    }
}

#navigator-option-selected > .top-block {
    position: absolute;
    top: -20px;
    right: 0;
    width: 20px;
    height: 20px;
    transition: none !important;
    
    .behind-block::after {
        position: absolute;
        display: block;
        content: '';
        top: 0px;
        width: 110%;
        height: 110%;
        background-color: $theme-highlight;
        z-index: -1;
    }
}

#navigator-option-selected > .bottom-block {
    position: absolute;
    bottom: -20px;
    right: 0;
    width: 20px;
    height: 20px;
    
    .behind-block::after {
        position: absolute;
        display: block;
        content: '';
        bottom: 0px;
        width: 110%;
        height: 110%;
        background-color: $theme-highlight;
        z-index: -1;
    }
}

.curve-block {
    width: 20px;
    height: 20px;
    display: block;
    position: absolute;
    content: "";
    background-color: $theme-primary;
    border-radius: 50px;
    box-sizing: border-box;
    transition: none !important;
}

.behind-block {
    right: 0;
    width: 10px;
    height: 10px;
    display: block;
    position: absolute;
    content: "";
    background-color: $theme-highlight;
}

.top-block > .behind-block {
    bottom: 0;
}

.bottom-block > .behind-block {
    top: 0;
}

.cls-1 {
    font-size: 200px;
    text-anchor: middle;
}

.cls-2 {
    font-size: 150px;
}

.cls-3 {
    font-size: 100px;
}

.navigator-drawer-button {
    position: absolute;
    left: 14px;
    top: 14px;
    width: 26px;
    height: 26px;
    z-index: 1001;

    &>div {
        position: absolute;
        border-radius: 2px;
        background-color: white;
        height: 21.053%;
        transition: all .1s cubic-bezier(0.075, 0.82, 0.165, 1);

        &.off {
            filter: drop-shadow(0 0 2px black);
        }
    }

    .ndb-top {
        &.off {
            left: 0;
            top: 8%;
            width: 100%;
        }

        &.on {
            left: 0;
            top: -9%;
            width: 136%;
            transform: rotate(45deg);
            transform-origin: left;
        }
    }

    .ndb-mid {
        &.off {
            left: 0;
            top: 40%;
            width: 100%;
        }

        &.on {
            left: 0;
            top: -9%;
            width: 136%;
            transform: rotate(45deg);
            transform-origin: left;
        }
    }

    .ndb-low {
        &.off {
            left: 0;
            top: 71.053%;
            width: 100%;
        }

        &.on {
            left: 0;
            bottom: -8%;
            width: 136%;
            transform: rotate(-45deg);
            transform-origin: left;
        }
    }
}