#images {
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.image-container {
    width: 100%;
    box-sizing: border-box;
    user-select: none;
    padding: 20px 10%;
}

.image-container-frame {
    max-width: 550px;
    overflow: hidden;
    margin: auto;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

