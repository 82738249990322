.paper {
    width: 100%;
    padding: 0 10%;
    box-sizing: border-box;
}

.paper-paragraph {
    margin-bottom: 18px;
    line-height: 2em;
    font-size: 16px;

    p {
        text-align: left;
    }
}

#introduction > p {
    text-align: center;
    text-indent: 0;
}

.pdf-container {
    width: 100%;
    padding: 0 5%;
    height: 90vh;
    display: flex;
    justify-content: center;

    iframe {
        width: 100%;
    }
}

.cite {
    h4 {
        font-weight: 700;
        margin-bottom: 2px;
    }

    h5 {
        font-weight: 400;
        margin-top: 2px;
        margin-bottom: 8px;
    }
}