@import "../theme";

.landing-root {
    position: relative;
    height: 100%;
    background-color: white;
    background-position: center;
    background-size: cover;
    display: flex;
    align-items: center;
    overflow-x: hidden;
}

.landing-cover {
    width: 100%;
    padding: 20px 20px;
    background: $theme-highlight;
    z-index: 100;
}

#looper {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    transform: translateZ(10px);
    display:inline;
}